import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';

import Layout from '../components/base/layout';
import Seo from '../components/base/seo';
import logFirebaseEvent from '../utils/firebase';

const routes = require('../routes');

const DownloadDetails = ({
  pageContext: { paper },
  pageContext: { book },
  pageContext: { class_obj },
}) => {
  // const { paper } = data;

  const [timeLeft, setTimeLeft] = useState(5);

  useEffect(() => {
    if (timeLeft === 0) {
      const link = document.createElement('a');
      link.href = paper.file_url;
      link.setAttribute('download', `${paper.name}.pdf`);
      document.body.appendChild(link);
      window.localStorage.setItem(
        'downloadCount',
        (Number(window.localStorage.downloadCount) || 0) + 1,
      );
      link.click();
      link.parentNode.removeChild(link);

      setTimeLeft(null);

      setTimeout(() => {
        navigate(-1, { replace: true });
      }, 1000);
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);

    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  useEffect(() => () => setTimeLeft(null), []);

  useEffect(() => {
    logFirebaseEvent('download_paper_open', {
      id: paper.paper_id,
      value: paper.name,
    });
  }, []);

  return (
    <Layout>
      <Seo
        title={`${paper.name} | ${book.name} | ${class_obj.class_name} | ${class_obj.subject}`}
        keywords={[
          paper.name,
          book.name,
          class_obj.class_name,
          class_obj.subject,
          ...JSON.parse(process.env.GATSBY_BASE_KEYWORDS),
        ]}
      />
      <main className="">
        <div>
          <div className="bg-secondary px-4 py-4" onClick={() => navigate(-1)}>
            <StaticImage
              src="../images/v-left.png"
              alt="hw-logo"
              placeholder="blurred"
              layout="fixed"
              width={10}
              height={18}
              className=""
            />
          </div>
          <div className="py-2 bg-secondary px-8 text-center border-b-2 border-gray-400">
            {/* <img className="w-48 my-6 mx-auto" src="img/hw-logo.png" alt="hw-logo" /> */}
            <a
              href={process.env.GATSBY_LOGO_LINK}
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../images/hw-logo.png"
                alt="hw-logo"
                placeholder="blurred"
                layout="fixed"
                width={150}
                height={150}
                className="w-48 my-2 mx-auto"
              />
            </a>

            <div className="my-2 font-bold text-2xl">
              Want access to more types of questions?
            </div>
            <div className="my-2">
              Get more than 7 lakh Case Study, Assertion Reasoning, Term-1 MCQs
              & many more types of questions with automatic correction
            </div>

            <a
              href={process.env.GATSBY_LOGO_LINK}
              target="_blank"
              rel="noreferrer"
            >
              <button
                className="bg-primary text-white font-bold py-2 px-4 rounded-2xl mb-2"
                type="button"
              >
                Download the homework app
              </button>
            </a>
          </div>
          <div className="m-4">
            <div className="font-bold text-3xl">
              Downloading sample paper in
              {' '}
              {timeLeft || 0}
            </div>
            <div
              className="
              flex
              rounded-lg
              overflow-hidden
              border border-gray-300
              shadow-lg
              mt-4
            "
            >
              <img className="w-32 p-2" src={paper.thumbnail_url} alt="book1" />
              <div className="p-2">
                <div className="font-bold text-2xl">{paper.name}</div>
                <div className="text-lg">
                  {paper.question_count}
                  {' '}
                  questions
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {' '}
    </Layout>
  );
};

export default DownloadDetails;
